import React, { useState, useEffect } from 'react';
import ConditionRow from '../../utils/conditionRow/conditionRow';
import { v4 as uuidv4 } from 'uuid';
import '../../utils/rightBar/Rightbar.css';
import Select from 'react-select';
import { customStyles } from '../../utils/helpers/styles';
import { options } from '../../utils/helpers/options';
import useAccessToken from '../../utils/helpers/getAccessToken';

function ChatBar( {selectedScanner, handleInsert, showToast} ) {
  
  const getAccessToken = useAccessToken();
  const [scannerName, setScannerName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [list, setList ] = useState('');
  const [startYear, setStartYear] = useState('2024');
  const [startMonth, setStartMonth] = useState('01');
  const [startDay, setStartDay] = useState('01');
  const [endYear, setEndYear] = useState('2024');
  const [endMonth, setEndMonth] = useState('01');
  const [endDay, setEndDay] = useState('01');
  const [market, setMarket] = useState('');
  const [type, setType] = useState('');
  const [selection, setSelection] = useState('strategy'); 
  const [accountSize, setAccountSize] = useState('');
  const [sizeType, setSizeType] = useState('pertrade');

  const [strategies, setStrategies] = useState([]);
  const [selectedStrategy, setSelectedStrategy] = useState('');
  const [portfolios, setPortfolios] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState(''); 
  
  const [conditions, setConditions] = useState([]);




  useEffect(() => {
    if (selectedScanner != null) {
      const fetchDetailsForScanner = async () => {
        try {  
          const accessToken = await getAccessToken();
          const response = await fetch(`${process.env.REACT_APP_GET_SCANNER_DETAILS}?scannerId=${selectedScanner.id}`
            , {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          const data = await response.json();
          
          setScannerName(data.name);
          setMarket(data.market);
          setType(data.type);
          setStartYear(data.start_year);
          setStartMonth(data.start_month);
          setStartDay(data.start_day);
          setEndYear(data.end_year);
          setEndMonth(data.end_month);
          setEndDay(data.end_day);
          setSymbol(data.symbol);
          setList(data.list);
          
        
          const loadedConditions = data.conditions.map(condition => ({
            id: uuidv4(),
            ...condition
          }));
        
          setConditions(loadedConditions);

        } catch (error) {
          console.error('Failed to fetch scanner details:', error);
        }
      };


      const fetchStrategiesandPortfolios = async () => {
        try {
          const accessToken = await getAccessToken();
          const response = await fetch(`${process.env.REACT_APP_HISTORICAL_STRATEGIES_PORTFOLIOS_API}`
            , {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            }            
          );
          const data = await response.json();
          setStrategies(data.strategies);
          setPortfolios(data.portfolios);
        } catch (error) {
          console.error('Failed to fetch strategies:', error);
        }
      };

      fetchDetailsForScanner();
      fetchStrategiesandPortfolios();
    
    } else {
      setScannerName('');
      setStartYear('2024');
      setStartMonth('01');
      setStartDay('01');
      setEndYear('2024');
      setEndMonth('01');
      setEndDay('01');
      setMarket('');
      setSymbol('');
      setList('');
      setType('');
      setConditions([]);
    }
  }, [selectedScanner]);


  const addCondition = () => {

    //structure for new condition is not correct
    const newCondition = {
      id: uuidv4(), 
    };
    const newConditions = [...conditions, newCondition];
    setConditions(newConditions);
  };


  const deleteCondition = (conditionIndex) => {
    const newConditions = conditions.filter((_, index) => index !== conditionIndex);    
    setConditions(newConditions);
  };

  const updateCondition = (conditionIndex, updatedCondition) => {
    const newConditions = conditions.map((condition, index) => 
      index === conditionIndex ? { ...condition, ...updatedCondition } : condition
    );
    setConditions(newConditions);
  };

  
  const [showStrategyDetails, setStrategyShowDetails] = useState(true);
  const [showEntryDetails, setEntryShowDetails] = useState(true);
  const [showSizeDefaults, setshowSizeDefaults] = useState(true);


  const toggleStrategyDetails = () => {
    setStrategyShowDetails(!showStrategyDetails);
  };

  const toggleEntryDetails = () => {
    setEntryShowDetails(!showEntryDetails);
  };

  const toggleSizeDefaults = () => {
    setshowSizeDefaults(!showSizeDefaults);
  };


  const clearScanner = () => {
    setScannerName('');
    setStartYear('2024');
    setStartMonth('01');
    setStartDay('01');
    setEndYear('2024');
    setEndMonth('01');
    setEndDay('01');
    setMarket('');
    setType('');
    setSymbol('');
    setList('');
    setConditions([]);
  };



  const handleRunClick = async () => {

    if (!scannerName) {
      showToast('Please enter a indicator name', 'error');
      return;
    }
    
    const payload = {
      name: scannerName,
      startYear: startYear,
      startMonth: startMonth,
      startDay: startDay,
      endYear: endYear,
      endMonth: endMonth,
      endDay: endDay,
      symbol: symbol,
      list: list,
      market: market,
      type: type,
      conditionals: conditions
    };

    
    try {
      const accessToken = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_RUN_SCANNER_API}`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.message && data.scannerId && data.scannerName) {
        handleInsert(data.scannerId, data.scannerName);
      }      
    } catch (error) {
      console.error('There was an error running the scanne', error);
    }


  };

  return (
    <div className="chatbar-container">


      <div className="chatbar-header">
        <div className="titletext">
        Position Sizing/Risk
        </div>
        <div className="chatbar-run">
        <button type="button" onClick={clearScanner}>Create New Risk Strategy</button>
        </div>
        <div className="chatbar-run">
        <button type="button" onClick={handleRunClick}>Save & Run</button>
        </div>

      </div>


      <div className="scrollable-content">
        
        <div className="strategyDetailsHeader">
            <button onClick={toggleStrategyDetails} className={`toggle-button ${showStrategyDetails ? 'expanded' : 'collapsed'}`}>
              <span className="arrow-icon">{showStrategyDetails ? '▼' : '▶'}</span>
            </button>
            <p className="strategy-details-title">Position Sizing Details:</p>
        </div>

        {showStrategyDetails && (
        <div className = "strategyDetails">
        
        <div className='details-row'>
          <div>Risk Strategy Name:</div>
        <input type="text" className="indicator-input" placeholder="Position Size Name" value={scannerName} onChange={(e) => setScannerName(e.target.value)}/>
        </div>
        
        <div className='details-row'>
          <div>Account Size:</div>
          <input type="text" className="indicator-input" placeholder="Account Size" value={accountSize} onChange={(e) => setAccountSize(e.target.value)}/>
        </div>

        <div style={{paddingLeft: '3.5%'}}>
        <label>
          <input
            type="radio"
            value="strategy"
            checked={selection === 'strategy'}
            onChange={() => setSelection('strategy')}
          />
          Strategy
        </label>
        <label style={{ marginLeft: '20px' }}> 
          <input
            type="radio"
            value="portfolio"
            checked={selection === 'portfolio'}
            onChange={() => setSelection('portfolio')}
          />
          Portfolio
        </label>
      </div>


      {/* Conditional rendering based on selection */}
      {selection === 'strategy' ? (
        <Select
          styles={customStyles}
          className="indicator-dropdown-select-portfolio2"
          value={selectedStrategy}
          onChange={setSelectedStrategy}
          options={strategies}
          isSearchable={true}
        />
      ) : (
        <Select
          styles={customStyles}
          className="indicator-dropdown-select-portfolio2"
          value={selectedPortfolio}
          onChange={setSelectedPortfolio}
          options={portfolios}
          isSearchable={true}
        />
      )}


        </div>
        )}

        <div className="strategyDetailsHeader">
            <button onClick={toggleSizeDefaults} className={`toggle-button ${showSizeDefaults ? 'expanded' : 'collapsed'}`}>
              <span className="arrow-icon">{showSizeDefaults ? '▼' : '▶'}</span>
            </button>
            <p className="strategy-details-title">Position Size Defaults:</p>
          </div>

        {showSizeDefaults && (
          <div className="strategyDetails">

          <Select
              styles={customStyles}
              className="indicator-dropdown-select-portfolio2"
              value={''}
              options={options}
              isSearchable={true}
          />

          </div>

        )}




        <div className="strategyDetailsHeader">
            <button onClick={toggleEntryDetails} className={`toggle-button ${showEntryDetails ? 'expanded' : 'collapsed'}`}>
              <span className="arrow-icon">{showEntryDetails ? '▼' : '▶'}</span>
            </button>
            <p className="strategy-details-title">Position Size Conditions:</p>
          </div>

        {showEntryDetails && (      
        <div className="chatbar-conditions">
          {conditions.map((condition, index) => (
            <ConditionRow 
              key={condition.id}
              conditionn={condition} 
              onConditionChange={(updatedCondition) => updateCondition(index, updatedCondition)}
              onDelete={() => deleteCondition(index)}
            />
          ))}
          <div className="add-condition">
            <button onClick={addCondition} type="button">Add Condition</button>
          </div>
        </div>
        )}    

      </div>
          
    </div>
  );
}

export default ChatBar;
