import React, { useState, useEffect } from 'react';
import '../../utils/statspanel/StatsPanel.css';
import StatsIcon from '../../../../images/pie-chart.svg'
import useAccessToken from '../../utils/helpers/getAccessToken';

function StatsPanel ({selectedPortfolioId}) {

    const getAccessToken = useAccessToken();
    const [highestGrossStrategy, setHighestGrossStrategy] = useState(null);
    const [lowestGrossStrategy, setLowestGrossStrategy] = useState(null);
    const [w_l_ratio, setWL_Ratio] = useState(null);
    const [r_r_ratio, setRR_Ratio] = useState(null);
    const [totalTrades, setTotalTrades] = useState(null);
    const [totalCommission, setTotalCommission] = useState(null);
    const [totalSlippage, setTotalSlippage] = useState(null);
    const [gross, setGross] = useState(null);
    const [netProfit, setNetProfit] = useState(null);
    const [averageWin, setAverageWin] = useState(null);
    const [averageLoss, setAverageLoss] = useState(null);
    const [BP, setBP] = useState(null);
    const [maxDrawdown, setMaxDrawdown] = useState(null);



    useEffect(() => {
        if (selectedPortfolioId != null) {
          const fetchStats = async () => {
            try {  
              const accessToken = await getAccessToken();
              const response = await fetch(`${process.env.REACT_APP_GET_PORTFOLIO_STATS_DETAILS}?portfolioId=${selectedPortfolioId}`
                , {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              );
              const data = await response.json();


              if (data) {                
                setHighestGrossStrategy(data.HighestGrossStrategyName != null ? data.HighestGrossStrategyName : "");
                setLowestGrossStrategy(data.LowestGrossStrategyName != null ? data.LowestGrossStrategyName : "");
                setWL_Ratio(data.win_percentage != null ? `${data.win_percentage.toFixed(2)}%` : "");
                setRR_Ratio(data.risk_reward_ratio != null ? `${data.risk_reward_ratio}:1` : "");
                setTotalTrades(data.trade_count != null ? data.trade_count : "");
                setTotalCommission(data.total_commissions != null ? `$${data.total_commissions.toFixed(2)}` : "");
                setTotalSlippage(data.total_slippage != null ? `$${data.total_slippage.toFixed(2)}` : "");
                setGross(data.gross_profit != null ? `$${data.gross_profit.toFixed(2)}` : "");
                setNetProfit(data.net_profit != null ? `$${data.net_profit.toFixed(2)}` : "");
                setAverageWin(data.average_win != null ? `$${data.average_win.toFixed(2)}` : "");
                setAverageLoss(data.average_loss != null ? `$${data.average_loss.toFixed(2)}` : "");
                setBP(data.bp_required != null ? `$${data.bp_required.toFixed(2)}` : "");
                setMaxDrawdown(data.max_drawdown != null ? `$${data.max_drawdown.toFixed(2)}` : "");

                
              } else {
                setHighestGrossStrategy("");
                setLowestGrossStrategy("");
                setWL_Ratio("");
                setRR_Ratio("");
                setTotalTrades("");
                setTotalCommission("");
                setTotalSlippage("");
                setGross("");
                setNetProfit("");
                setAverageWin("");
                setAverageLoss("");
                setBP("");
                setMaxDrawdown("");
              }

            } catch (error) {
              console.error('Failed to fetch trades:', error);
            }
          };
          fetchStats();
        } else {
          setHighestGrossStrategy("");
          setLowestGrossStrategy("");
          setWL_Ratio("");
          setRR_Ratio("");
          setTotalTrades("");
          setTotalCommission("");
          setTotalSlippage("");
          setGross("");
          setNetProfit("");
          setAverageWin("");
          setAverageLoss("");
          setBP("");
          setMaxDrawdown("");
        }
      }, [selectedPortfolioId]);

    return (
        <div className = "stats-container">
            <div className = "stats-header">
              <img src={StatsIcon} alt="Logo" className="logo" style={{paddingTop: '2px', paddingRight: '9px', height: '20px', width: 'auto'}}/>
              <h1>Portfolio Statistics</h1>
            </div>
            <p style = {{margin: 2, marginTop: 10}}><strong>Average Win:</strong> {averageWin}</p>
            <p style = {{margin: 2}}><strong>Highest Gross Strategy:</strong> {highestGrossStrategy}</p>
            <p style = {{margin: 2}}><strong>Lowest Gross Strategy:</strong> {lowestGrossStrategy}</p>
            <p style = {{margin: 2}}><strong>Average Loss:</strong> {averageLoss}</p>
            <p style = {{margin: 2}}><strong>W/L ratio:</strong> {w_l_ratio}</p>
            <p style = {{margin: 2}}><strong>Risk Reward Ratio:</strong> {r_r_ratio}</p>
            <p style = {{margin: 2}}><strong>Total Number of Trades:</strong> {totalTrades}</p>
            <p style = {{margin: 2}}><strong>Total Commission:</strong> {totalCommission}</p>
            <p style = {{margin: 2}}><strong>Total Slippage:</strong> {totalSlippage}</p>
            <p style = {{margin: 2}}><strong>Gross:</strong> {gross}</p>
            <p style = {{margin: 2}}><strong>Net Profit:</strong> {netProfit}</p>
            <p style = {{margin: 2, color: "red"}}><strong>Max Drawdown:</strong>{maxDrawdown}</p>
            <p style = {{margin: 2, color: "red"}}><strong>Buying Power Required:</strong> {BP}</p>
        </div>
    );
};

export default StatsPanel;