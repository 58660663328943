import { useState, useEffect } from 'react';
import { socket } from '../../../socket'; 
import './LogsComponent.css';
import useAccessToken from '../../backtesting/utils/helpers/getAccessToken';

const LogsComponent = ({detailLive}) => {
  const [logs, setLogs] = useState([]);
  const getAccessToken = useAccessToken();

  useEffect(() => {
    
    const fetchLogs = async () => {
        try {
          const accessToken = await getAccessToken();
          const response = await fetch(`${process.env.REACT_APP_GET_LIVE_LOGS}?strategyId=${detailLive.id}`
            , {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          const data = await response.json();
          setLogs(data);
        } catch (error) {
          console.error('Failed to fetch logs:', error);
        }
    };

    fetchLogs();

    socket.on('frontend_receive_logs', (newLog) => {
      setLogs((prevLogs) => [...prevLogs, newLog]);
    });

    return () => {
      socket.off('frontend_receive_logs');
    };
  }, []);  
  
  
  return (
    <div className = "detailsTable">
    <div className = "stats-header">
      <h1 style={{paddingLeft: '1%', paddingTop: '0.7%'}}>Logs</h1>
    </div>
    
      <div className="logs-container">
        {logs.map((log, index) => (
          <div key={index} className="log-entry">
            <span className="timestamp">{log.timestamp}:</span>
            <span className="message">{log.message}</span>
          </div>
        ))}
      </div>  
    </div>
  );
};

export default LogsComponent;
