import React, { useState, useEffect } from 'react';
import useAccessToken from '../../backtesting/utils/helpers/getAccessToken';

function AddAccount({togglePopup, allAccounts, setAllAccounts, showToast}) {

  const getAccessToken = useAccessToken();

  const brokers = [
      { key: 'newstockcity', header: 'New Stock City (DEMO)' },
      { key: 'alpaca', header: 'Alpaca' },
      { key: 'ibkrlocal', header: 'Interactive Brokers (local)'},
      { key: 'ibkr', header: 'Interactive Brokers'},
      { key: 'tradestation', header: 'TradeStation'},
    ];  
  
  const [selectedBroker, setSelectedBroker] = useState(brokers[0].key);
  const [accountID, setAccountID] = useState('');

  const handleLiveRunClick = async () => {
    const accessToken = await getAccessToken();
    const payload = {
      broker: selectedBroker,
      accountID: accountID,
    };

    if (selectedBroker === 'tradestation') {


      try {
        const response = await fetch(`${process.env.REACT_APP_ADD_ACCOUNT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(payload),
        });

        const data = await response.json();
        if (data.auth_url) {
            window.location.href = data.auth_url;
        }
      } catch (error) {
          console.error('Error:', error);
      }

    //this code is not gonna work now because endpoint on backend is only for oauth2 currently, only small changes needed though
    } else {

      try {
        
        const response = await fetch(`${process.env.REACT_APP_ADD_ACCOUNT}`, { 
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(payload),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        } else {
          const newAccount = await response.json();
          console.log('New Account:', newAccount);
          const updatedAccounts = [...allAccounts, newAccount];
          setAllAccounts(updatedAccounts);
          console.log('Account has been successfully');

      }
      } catch (error) {
        console.error('There was an error adding the account', error);
      }
    }
  }
  
  const handleClick = () => {
    handleLiveRunClick();
    togglePopup();
  }




  return (
    <div>
        <div className="popup-box">
          <div className="box">
            <h2>Select Broker</h2>
            
            <div className="select-container">

               <select 
                 className="color-select"
                 value={selectedBroker}
                 onChange={(e) => setSelectedBroker(e.target.value)}
               >
                 {brokers.map((broker) => (
                   <option key={broker.key} value={broker.key}>
                     {broker.header}
                   </option>
                 ))}
               </select>



               {selectedBroker === 'ibkrlocal' && (
              <div>
                <input 
                  type="text" 
                  value={accountID}
                  onChange={(e) => setAccountID(e.target.value)}
                  placeholder="Enter Account ID"
                  className="account-id-input"
                />
              </div>
            )}


            </div>

            <button onClick={togglePopup}>Cancel</button>
            <button onClick={handleClick}>Confirm</button>
          </div>
        </div>
      
    </div>
  );
}

export default AddAccount;
