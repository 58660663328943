import './strategies.css'; 
import Table from '../utils/table';
import React from 'react';
import  MainChart from '../liveChart/chart';
import {useState, useEffect} from 'react';
import Popup from '../utils/add_strategy';
import { socket } from '../../../socket';
import LogsComponent from '../utils/logsComponent';
import useAccessToken from '../../backtesting/utils/helpers/getAccessToken';

const StrategyDeployments = () => {

  const getAccessToken = useAccessToken();
  const [detailLiveStrategy, setDetailLiveStrategy] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [liveStrategies, setLiveStrategies] = useState([]); 

  
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };


	const fetchLiveStrategies = async () => {
		try {
      const accessToken = await getAccessToken();
		  const response = await fetch(`${process.env.REACT_APP_GET_LIVE_STRATEGIES}`
        , {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
		  const data = await response.json();
		  setLiveStrategies(data);
		} catch (error) {
		  console.error('Failed to fetch strategies:', error);
		}
	};

  const removeLiveStrategy = async (strategyId) => {
    try {
      const payload = {
          strategyId: strategyId,
      };

      const accessToken = await getAccessToken();

      const response = await fetch(`${process.env.REACT_APP_REMOVE_LIVE_API}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data.message) {
          handleRemoveStrategy(strategyId);
      } else if (data.error) {
          console.error(data.error);
      }
    } catch (error) {
        console.error('Error:', error);
    }
  }

	const handleRemoveStrategy = (strategyId) => {
		const updatedStrategies = liveStrategies.filter(strategy => strategy.id !== strategyId);
		setLiveStrategies(updatedStrategies);
	};


  const handleRemoveClick = (strategy) => {
    removeLiveStrategy(strategy.id); 
  };

  const handleDetailsClick = (strategy) => {
    const dataToSend = {
      strategyId: strategy.id,  
    };

    socket.emit('frontend_needs', dataToSend);

    setDetailLiveStrategy(strategy);
  }

	useEffect(() => {
	  fetchLiveStrategies();
	}, []);


  const columns = [
    { key: 'name', header: 'Strategy Name' },
    { key: 'broker', header: 'Broker: Account' },
    { key: 'status', header: 'Status' },
    { key: 'pnl', header: 'P&L' },
  ];



  
  return (
    <div className = "liveTradingHome">
        <div className = "strategyTable">
        
        <div className='headerBar'>
          <h1 style={{fontSize: '24px'}}> Strategies </h1>
          <button onClick={togglePopup} className="addStrategyButton">Add Strategy</button>
        </div>
        
        {isOpen && (
        <Popup togglePopup={togglePopup} liveStrats = {liveStrategies} setLive = {setLiveStrategies}/>
        )}

          <Table 
            columns={columns}
            data={liveStrategies}
            onDetailClick={handleDetailsClick}
            onDeleteClick={handleRemoveClick}          
          />
        
        
        </div>
        <div className="bottom-half">
        <div className = "chartContainer">
           {detailLiveStrategy && <MainChart detailLive={detailLiveStrategy}/>}
        </div>
           {detailLiveStrategy && (<LogsComponent detailLive={detailLiveStrategy}/>)}
        </div>
    </div>
  );
};

export default StrategyDeployments;