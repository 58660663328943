import Table from '../utils/table';
import {useState, useEffect} from 'react';
import useAccessToken from '../../backtesting/utils/helpers/getAccessToken';

function Orders() {

    const [orders, setOrders] = useState([]);
    const getAccessToken = useAccessToken();

    const columns = [
        { key: 'status', header: 'Status' },
        { key: 'symbol', header: 'Symbol' },
        { key: 'broker', header: 'Broker'},
        { key: 'account', header: 'Account'},
        { key: 'side', header: 'Side' },
        { key: 'quantity', header: 'Quantity' },
        { key: 'open', header: 'Open' },
        { key: 'filled', header: 'Filled' },
        { key: 'price', header: 'Price' },
        { key: 'route', header: 'Route' },
        { key: 'type' , header: 'Type'}, 
        { key: 'time' , header: 'Time'}, 
        { key: 'tif' , header: 'TIF'}, 
    ];

    
    const fetchOrders = async () => {
        try {
            const accessToken = await getAccessToken();
            const response = await fetch(`${process.env.REACT_APP_GET_ORDERS}`
                , {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${accessToken}`,
                    },
                  }
            );
            const data = await response.json();
            setOrders(data);
          } catch (error) {
            console.error('Failed to fetch orders:', error);
          }
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    return (
    <div>
        <h1 style={{paddingLeft: '0.5%', fontSize: '24px'}}> Orders </h1>
        <Table
            columns = {columns}
            data = {orders}        
        />
        
    </div>
  );
}


export default Orders;