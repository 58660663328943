import React, { useState, useEffect, PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Dot } from 'recharts';
import useAccessToken from '../../utils/helpers/getAccessToken';

function EquityCurve ({trades, selectedStrategyId, onTradeSelect}) {

      const getAccessToken = useAccessToken();
      const [equityData, setEquityData] = useState([]);
      const [yMinEquity, setYMinEquity] = useState(0);

      useEffect(() => {
        if (selectedStrategyId != null) {
          const fetchCurve = async () => {
            try {  
              const accessToken = await getAccessToken();
              const response = await fetch(`${process.env.REACT_APP_GET_EQUITY_CURVE_DETAILS}?strategyId=${selectedStrategyId}`
              , {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${accessToken}`,
                },
              }
              );
              const data = await response.json();

              
              const formattedData = data.map(item => {
                const formattedEndDate = item.end_date.split(' ').slice(0, 4).join(' ');
                return {
                  name: formattedEndDate, 
                  Equity: parseFloat(item.equity_curve),
                  completeTradeID: item.completetradeID
                };
              });

              setEquityData(formattedData);
              
              const yMinValue = Math.min(...formattedData.map(item => item.Equity)) - 1000;
              setYMinEquity(yMinValue);

            } catch (error) {
              console.error('Failed to fetch trades:', error);
            }
          };
        
          fetchCurve();
        } else {
          setEquityData([]);
          setYMinEquity(0);
        }
      }, [selectedStrategyId]);

    
      const handleDotClick = (data, index) => {
        const matchingTrade = trades.find(trade => trade.completetradeID === data.completeTradeID);

        if (matchingTrade) {
          onTradeSelect(matchingTrade);
        } else {
          console.log('No matching trade found');
        }

      };
    
      return (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={equityData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis domain={[yMinEquity]}/>
            <Tooltip />
            <Legend />
            <Line 
            type="linear" 
            dataKey="Equity" 
            stroke="green" 
            
            activeDot={(props) => (
              <Dot {...props} r={8} style={{cursor: "pointer"}} onClick={() => handleDotClick(props.payload, props.index)}
               />
            )}            
            />
            
          </LineChart>
        </ResponsiveContainer>
      );
  }

export default EquityCurve;
  