import React, { useState, useEffect } from 'react';
import './listbar.css';
import useAccessToken from '../../utils/helpers/getAccessToken';

function ListBar({ items, onItemSelect, handleDelete, selectedItem, selectedRange, setSelectedRange, itemType, deleteEndpoint, style }) {
  const [contextMenu, setContextMenu] = useState(null);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
  const getAccessToken = useAccessToken();
  
  const onDeleteItem = async () => {
    try {
      const accessToken = await getAccessToken();
      const response = await fetch(deleteEndpoint, {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ strategy_ids: selectedRange }),
      });

      const data = await response.json();

      if (data.message) {
          handleDelete(selectedRange, setLastSelectedIndex);
      } else if (data.error) {
          console.error(data.error);
      }
    } catch (error) {
        console.error('Error:', error);
    }
  }

  const handleItemClick = (item, index, event) => {
    if (event.shiftKey && lastSelectedIndex !== null) {
      const rangeStart = Math.min(lastSelectedIndex, index);
      const rangeEnd = Math.max(lastSelectedIndex, index);
      const range = items.slice(rangeStart, rangeEnd + 1).map(i => i.id);
      setSelectedRange(range); 
    } else {
      setSelectedRange([item.id]); 
      setLastSelectedIndex(index); 
      onItemSelect(item);
    }

  };

  const handleRightClick = (event, item) => {
    event.preventDefault();
    setContextMenu({
      visible: true,
      x: event.clientX,
      y: event.clientY,
      itemId: item.id,
    });
  };

  const handleContextMenuAction = (action) => {
    setContextMenu(null);
    if (action === 'delete') {
      onDeleteItem();
    }
  };


  useEffect(() => {
    if (selectedItem) {
      setSelectedRange([selectedItem.id]);
      setLastSelectedIndex(items.findIndex(item => item.id === selectedItem.id));
    } else {
      setSelectedRange([]);
      setLastSelectedIndex(null);
    }
    const handleClick = () => setContextMenu(null);
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [selectedItem]);

  return (
    <div className = "wholee-container">
      <div className="listbar-title">{itemType}</div> 
    <div className="listbar-container" style = {style}>


      {items.map((item, index) => (
        <div
          key={item.id}
          className={`listbar-item ${selectedRange.includes(item.id) ? 'highlight' : ''}`}
          onClick={(e) => handleItemClick(item, index, e)}
          onContextMenu={(e) => handleRightClick(e, item)}
        >
          <div className="item-name">{item.name}</div>
        </div>
      ))}

      {contextMenu?.visible && (
        <div
          className="context-menu"
          style={{
            position: 'absolute',
            top: `${contextMenu.y}px`,
            left: `${contextMenu.x}px`,
          }}
        >
          <div className="context-menu-item" onClick={() => handleContextMenuAction('delete', contextMenu.itemId)}>Delete</div>
          <div className="context-menu-item" onClick={() => handleContextMenuAction('export', contextMenu.itemId)}>Export</div>
        </div>
      )}
    </div>
    </div>
  );
}

export default ListBar;
