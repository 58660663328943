import { io } from 'socket.io-client';

const URL = process.env.REACT_APP_LIVETRADING_WS_URL;
let socket;

const backtestingURL = process.env.REACT_APP_BACKTESTING_WS_URL;
let backtestSocket;

// Function to initialize sockets
export function initializeSockets() {
    if (!socket) {
        socket = io(URL);
    }
    if (!backtestSocket) {
        backtestSocket = io(backtestingURL);
    }
}

// Function to disconnect sockets (optional for cleanup)
export function disconnectSockets() {
    if (socket) {
        socket.disconnect();
        socket = null;
    }
    if (backtestSocket) {
        backtestSocket.disconnect();
        backtestSocket = null;
    }
}

// Exports for use in other files
export { socket, backtestSocket };
