import React, { useEffect, useRef, useState,useMemo,  memo } from 'react';
import { widget } from '../../../../charting_library';
import { createDataFeed  } from './utils/tradingViewUtils';
import useAccessToken from '../../utils/helpers/getAccessToken';

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function backToDate(unixTime){
    const date = new Date(unixTime * 1000); 
    const year = date.getUTCFullYear(); 
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); 
    const day = date.getUTCDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function formatTimeDelta(seconds) {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = seconds % 60;
  
	const hoursDisplay = hours.toString().padStart(2, '0');
	const minutesDisplay = minutes.toString().padStart(2, '0');
	const secondsDisplay = remainingSeconds.toString().padStart(2, '0');
  
	return `${hoursDisplay}:${minutesDisplay}:${secondsDisplay}`;
  }


const MainChart = memo(({selectedTrade, selectedIndicator}) => {

	const historicalData = useRef(null);
	const indicatorData = useRef(null);
	const getAccessToken = useAccessToken();
	const [trades, setTrades] = useState(null);

	const chartContainerRef = useRef();
	const datafeed = createDataFeed();
	const moment = require('moment');
	const formattedDate = formatDate(selectedTrade.date);
	
	const date = new Date(formattedDate);
	date.setDate(date.getDate() + 1);
	const nextDayFormattedDate = formatDate(date.toISOString().substring(0, 10));	
	
	const timeFromDb = '00:00:00'; 

	const dateTimeUtcUnix = moment.utc(`${nextDayFormattedDate} ${timeFromDb}`, 'YYYY-MM-DD HH:mm:ss');
	
	const plusOneMinute = dateTimeUtcUnix.clone().add(800, 'minutes').unix();
	
	const plusMultipleMinutes = dateTimeUtcUnix.clone().add(1300, 'minutes').unix();
	
	const plusOneDay = dateTimeUtcUnix.clone().add(1, 'days').unix();
    const minusOneDay = dateTimeUtcUnix.clone().subtract(1, 'days').unix();

	const plusThreeDays = dateTimeUtcUnix.clone().add(7, 'days').unix();
	const minusThreeDays = dateTimeUtcUnix.clone().subtract(7, 'days').unix();


    useEffect(() => {
		if (selectedTrade != null) {
          const fetchTradeDetails = async () => {
            try {  
			  const accessToken = await getAccessToken();
              const response = await fetch(`${process.env.REACT_APP_GET_TRADE_DETAILS}?tradeId=${selectedTrade.completetradeID}`
                , {
					method: 'GET',
					headers: {
					  'Content-Type': 'application/json',
					  Authorization: `Bearer ${accessToken}`,
					},
				  }
			  );
              const data = await response.json();
			  setTrades(data);

            } catch (error) {
              console.error('Failed to fetch trades:', error);
            }
          };
    
          fetchTradeDetails();
        }
      }, [selectedTrade]);



	useEffect(() => {
		
		const fetchIndicatorDetails = async () => {
            try {  
			  const accessToken = await getAccessToken();
              const response = await fetch(`${process.env.REACT_APP_GET_CUSTOM_INDICATOR_CHART_DETAILS}?indicatorId=${selectedIndicator.id}`
                , {
					method: 'GET',
					headers: {
					  'Content-Type': 'application/json',
					  Authorization: `Bearer ${accessToken}`,
					},
				  }
			  );
              const data = await response.json();
			  indicatorData.current = data;
            } catch (error) {
              console.error('Failed to fetch indicator details:', error);
            }
          };

		const widgetOptions = {
			
			symbol: selectedTrade.symbol,	
			datafeed: createDataFeed(minusThreeDays, plusThreeDays, selectedIndicator.id, historicalData),
			interval: '1',																	
			timezone: "America/New_York",
			container: chartContainerRef.current,
			library_path: '/charting_library/',
			locale: getLanguageFromURL() || 'en',
			disabled_features: ['use_localstorage_for_settings'],
			enabled_features: ['study_templates'],
			charts_storage_url: 'https://saveload.tradingview.com',
			charts_storage_api_version: '1.1',
			timeframe: { from: plusOneMinute, to: plusMultipleMinutes },						
			
			client_id: 'tradingview.com',
			user_id: 'public_user_id',
			fullscreen: false,
			autosize: true,
			studies_overrides: {},


			custom_indicators_getter: async (PineJS) => {
				await fetchIndicatorDetails();
				const indicators = indicatorData.current.map(indicator_data => {
				  const metainfo = {
					_metainfoVersion: 51,
					id: `${indicator_data.name}@tv-basicstudies-1`,
					name: indicator_data.name,
					description: indicator_data.name,
					shortDescription: indicator_data.name,
					is_price_study: true,
					isCustomIndicator: true,
					format: {
					  type: "price",
					  precision: 2,
					},
					plots: indicator_data.plots.map((plot, index) => ({
					  id: `plot_${index}`,
					  type: plot.type || "line",
					})),
					defaults: {
					  styles: indicator_data.plots.reduce((styles, plot, index) => {
						styles[`plot_${index}`] = {
						  linestyle: plot.linestyle || 0,
						  linewidth: plot.linewidth || 1,
						  plottype: plot.plottype || 0,
						  trackPrice: !!plot.trackPrice,
						  transparency: plot.transparency || 0,
						  visible: plot.visible !== false,
						  color: plot.color || '#000080', 
						};
						return styles;
					  }, {}),
					  inputs: {},
					},
					styles: indicator_data.plots.reduce((styles, plot, index) => {
					  styles[`plot_${index}`] = {
						title: plot.title || `Plot ${index}`,
						histogramBase: plot.histogramBase || 0,
						joinPoints: !!plot.joinPoints,
					  };
					  return styles;
					}, {}),
					inputs: [],
				  };
			  
				  const constructor = function () {
					this.main = function (context, inputCallback) {
					  this._context = context;
					  this._input = inputCallback;
					  const symbolTime = PineJS.Std.time(this._context) / 1000;
					  if (Number.isNaN(symbolTime)) {
						return; 
					  }
					  
					  const matchingData = historicalData.current.find(item => item.unixTime === symbolTime);
					  
					  if (!matchingData) return;
			  
					  return indicator_data.plots.map(plot => matchingData[plot.key]);
					};
				  };
			  
				  return { name: indicator_data.name, metainfo, constructor };
				});
			  
				return Promise.resolve(indicators);
			  },

		};

		const tvWidget = new widget(widgetOptions);

		tvWidget.onChartReady(() => {
			tvWidget.headerReady().then(() => {
				const plusOneDayDate = backToDate(plusOneDay);
				const minusOneDayDate = backToDate(minusOneDay);
				const plusThreeDayDate = backToDate(plusThreeDays);
				const minusThreeDayDate = backToDate(minusThreeDays);
				


				indicatorData.current.map(indicator_data => {
					tvWidget.activeChart().createStudy(indicator_data.name)
				});
				
				
				const values = tvWidget.activeChart().exportData({ includeDisplayedValues: true })
				
				trades.forEach((trade) => {
					const momentt = require('moment-timezone');
					const new_date_format_0 = trade.date.split(' ').slice(0, 4).join(' ')
					const new_date_format = formatDate(new_date_format_0)
					const new_time = formatTimeDelta(trade.time)
					const estTime = momentt.tz(`${new_date_format} ${new_time}`, 'YYYY-MM-DD HH:mm:ss', 'America/New_York');
					const dateTimeUtcUnix2 = estTime.clone().utc();
					const unixTimestampUtc = dateTimeUtcUnix2.valueOf();
					const unixTimestampUtcSeconds = Math.floor(unixTimestampUtc / 1000);
					
					let icon, angle, color;
					if (trade.side === 'buy') {
					  icon = 0xf0da; 
					  angle = 6.28319; 
					  color = 'green';
					} else if (trade.side === 'sell') {
					  icon = 0xf0da; 
					  angle = 3.14159; 
					  color = 'red';
					}
			  
					tvWidget.activeChart().createShape(
					  {
						time: unixTimestampUtcSeconds, 
						price: parseFloat(trade.price),
					  },
					  {
						shape: "icon",
						icon: icon,
						zOrder: "top",
						overrides: { 
						  size: 25, 
						  angle: angle, 
						  color: color 
						}, 
						lock: true,
						disableSelection: true
					  }
					);
				  });


				const button = tvWidget.createButton();
				button.setAttribute('title', 'Click to show a notification popup');
				button.classList.add('apply-common-tooltip');
				button.addEventListener('click', () => tvWidget.showNoticeDialog({
					title: 'Notification',
					body: 'TradingView Charting Library API works correctly',
					callback: () => {
						console.log('Noticed!');
					},
				}));

				button.innerHTML = 'Check API';
			});

		});

		return () => {
			tvWidget.remove();
		};
	});

	return (
		<div
			ref={chartContainerRef}
			className={'MainChart2'}
		/>
	);
});

export default MainChart;