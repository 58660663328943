import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";

const useAccessToken = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [accessToken, setAccessToken] = useState(null);

    const getAccessToken = async () => {
        if (!accessToken) {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_API_AUDIENCE,
            });
            setAccessToken(token);
            return token;
        }
        return accessToken;
    };

    useEffect(() => {
        const fetchToken = async () => {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_API_AUDIENCE,
            });
            setAccessToken(token);
        };
        fetchToken();
    }, [getAccessTokenSilently]);

    return getAccessToken;
};

export default useAccessToken;