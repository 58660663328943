import { socket } from '../../../socket';

const configurationData = {
    supports_search: true,
    supports_group_request: false,
    supports_marks: true,
    supports_timescale_marks: true,
    supports_time: true,
    exchanges: [
        { value: "", name: "All Exchanges", desc: "" },
        { value: "NasdaqNM", name: "NasdaqNM", desc: "NasdaqNM" },
        { value: "NYSE", name: "NYSE", desc: "NYSE" }
    ],
    symbols_types: [
        { name: "All types", value: "" },
        { name: "Stock", value: "stock" },
        { name: "Index", value: "index" }
    ],
    supported_resolutions: ["1", "5", "30", "60", "1D"],
};

const getSymbolInfoFromBackend = async (symbolName, extension) => {

    const symbolInfo = {
        ticker: symbolName,
        name: symbolName,
        description: symbolName + " stock",
        type: "stock",
        session: '24x7',
        timezone: 'America/New_York',
        exchange: 'NASDAQ',
        minmov: 1,
        pricescale: 100,
        has_intraday: true,
        visible_plots_set: 'ohlcv',
        has_weekly_and_monthly: false,
        supported_resolutions: ["1", "5", "30", '60', '1D'],
        volume_precision: 2,
        data_status: 'endofday',
    };

    return symbolInfo;

};

const getHistoricalBarsBackend = async (symbol, resolution, countBack, selectedStrategyId) => {
    const url = `${process.env.REACT_APP_DISPLAY_CHART}?symbol=${symbol}&resolution=${resolution}&countBack=${countBack}&strategyId=${selectedStrategyId}`;
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch historical bars for ${symbol}`);
    }
    return response.json();
};


export const createDataFeed = (selectedStrategyId, tvWidget, historicalData) => {
    let prevLength = 0;
    return {
        onReady: (callback) => {
            console.log('[onReady]: Method call');
            setTimeout(() => callback(configurationData), 0);
        },
        
        resolveSymbol: async (
            symbolName,
            onSymbolResolvedCallback,
            onResolveErrorCallback,
            extension
        ) => {
            try {
                const symbolInfo = await getSymbolInfoFromBackend(symbolName, extension);
                onSymbolResolvedCallback(symbolInfo);
            } catch (err) {
                onResolveErrorCallback('Symbol cannot be resolved: ' + err.message);
            }
        },
        
        getBars: async (
            symbolInfo, 
            resolution,
            periodParams, 
            onHistoryCallback, 
            onErrorCallback
        ) => {
            try {
                const { from, to, countBack } = periodParams;
                const rawBars = await getHistoricalBarsBackend(symbolInfo.ticker, resolution,  countBack, selectedStrategyId);
                
                if  (prevLength === rawBars.length) {
                    onHistoryCallback([], { noData: true });
                    return;
                }
                
                prevLength = rawBars.length;
                console.log('here getting the bars:');
                historicalData.current = rawBars;
                const bars = rawBars.map(bar => ({
                    time: bar.t, 
                    open: parseFloat(bar.o),
                    high: parseFloat(bar.h),
                    low: parseFloat(bar.l),
                    close: parseFloat(bar.c),
                    volume: parseInt(bar.v)
                }));

                bars.sort((a, b) => a.time - b.time);
                console.log('Length of bars:', bars.length);
                
                
                onHistoryCallback(bars, { noData: false });



            } catch (err) {
                onErrorCallback(err.message);
            }
        },

        subscribeBars: async(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
            
            const handleStockData = (data) => {
                if (data.resolution === resolution) {
                    updateHistoricalData(historicalData, data);
                    onRealtimeCallback({
                            time: data.t,
                            open: parseFloat(data.o),
                            high: parseFloat(data.h),
                            low: parseFloat(data.l),
                            close: parseFloat(data.c),
                            volume: data.v
                        });
                } 

                if (data.type === 'trade') {

                    tvWidget.current.activeChart().createShape(
                        {
                            time: data.t,
                            price: parseFloat(data.p),
                        },
                        {
                            shape: "icon",
                            icon: 0xf0da,
                            zOrder: "top",
                            overrides: {
                                size: 25,
                                angle: data.side === 'buy' ? 6.28319 : 3.14159,
                                color: data.side === 'buy' ? 'green' : 'red'
                            },
                            lock: true,
                            disableSelection: true
                        }

                    );
                }

            }

            socket.on('frontend_receive', handleStockData);
        
        },


    };
};

function updateHistoricalData(historicalData, newBar) {
    let found = false;
    for (let i = 0; i < historicalData.current.length; i++) {
        if (historicalData.current[i].t === newBar.t) {
            historicalData.current[i] = newBar;
            found = true;
            break;
        }
    }

    if (!found) {
        historicalData.current.push(newBar);
    }
}