import React, { useState, useEffect } from 'react';
import ConditionRow from './conditionRow';
import { v4 as uuidv4 } from 'uuid';
import '../../utils/rightBar/Rightbar.css';
import useAccessToken from '../../utils/helpers/getAccessToken';

function ChatBar( {selectedStrategy, onStrategySelect, onTradeSelect, handleInsert, showToast} ) {
  
  const [conditions, setConditions] = useState([]);
  const [portfolioName, setPortfolioName] = useState('');
  const [accountSize, setAccountSize] = useState('');
  const getAccessToken = useAccessToken();

  useEffect(() => {
    if (selectedStrategy != null) {
      const fetchDetailsForStrategy = async () => {
        try {  
          const accessToken = await getAccessToken();
          const response = await fetch(`${process.env.REACT_APP_GET_PORTFOLIO_DETAILS}?portfolioId=${selectedStrategy.id}`
            , {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          const data = await response.json();
          setPortfolioName(data.portfolioName);
          setAccountSize(data.accountSize);
          
                
          const loadedConditions = data.strats.map(strat => ({
              id: uuidv4(),
              strategyName: strat.strategyName || '', 
              strategyId: strat.strategyId || '',
            }));

          
          setConditions(loadedConditions);


        } catch (error) {
          console.error('Failed to fetch strategies:', error);
        }
      };

      fetchDetailsForStrategy();
    } else {
      setPortfolioName('');
      setAccountSize('');
      setConditions([]);
    }
  }, [selectedStrategy]);


  const addStrategy = () => {
    const newCondition = {
      id: uuidv4(), 
      strategyName: '',
      strategyId: '', 
    };
    const newConditions = [...conditions, newCondition];
    setConditions(newConditions);
  };


  const deleteCondition = (conditionIndex) => {
    const newConditions = conditions.filter((_, index) => index !== conditionIndex);    
    setConditions(newConditions);
  };

  const updateCondition = (conditionIndex, updatedCondition) => {
    const newConditions = conditions.map((condition, index) => 
      index === conditionIndex ? { ...condition, ...updatedCondition } : condition
    );
    setConditions(newConditions);
    console.log(conditions);
  };


  
  const [showStrategyDetails, setStrategyShowDetails] = useState(true);
  const [showEntryDetails, setEntryShowDetails] = useState(true);


  const toggleStrategyDetails = () => {
    setStrategyShowDetails(!showStrategyDetails);
  };

  const toggleEntryDetails = () => {
    setEntryShowDetails(!showEntryDetails);
  };


  const clearPortfolio = () => {
    setPortfolioName('');
    setAccountSize('');
    setConditions([]);
    //not sure what this code is
    onStrategySelect(null);
    onTradeSelect(null);
  };



  //this is when sending an order to run

  const handleRunClick = async () => {
    
    
    if (!portfolioName) {
      showToast('Please enter a portoflio name', 'error');
      return;
    }
    
    
    const payload = {
      portfolioName: portfolioName,
      accountSize: accountSize,
      strategies: conditions.map(strategy => ({
        strategyName: strategy.strategyName,
        strategyId: strategy.strategyId,
      })),

    };
    
    try {
      const accessToken = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_RUN_PORTFOLIO_API}`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.message && data.portfolioId && data.portfolioName) {
        handleInsert(data.portfolioId, data.portfolioName);
      }      
    } catch (error) {
      console.error('There was an error running the portoflio', error);
    }


  };

  return (
    <div className="chatbar-container">


      <div className="chatbar-header">
        <div className="titletext">
        Portfolio Builder
        </div>
        <div className="chatbar-run">
        <button type="button" onClick={clearPortfolio}>Create New Portfolio</button>
        </div>
        <div className="chatbar-run">
        <button type="button" onClick={handleRunClick}>Save & Run</button>
        </div>

      </div>


      <div className="scrollable-content">
        
        <div className="strategyDetailsHeader">
            <button onClick={toggleStrategyDetails} className={`toggle-button ${showStrategyDetails ? 'expanded' : 'collapsed'}`}>
              <span className="arrow-icon">{showStrategyDetails ? '▼' : '▶'}</span>
            </button>
            <p className="strategy-details-title">Portfolio Details:</p>
        </div>

        {showStrategyDetails && (
        <div className = "strategyDetails">
        
        <div className='details-row'>
          <div>Portfolio Name:</div>
          <input type="text" className="indicator-input" placeholder="Portfolio Name" value={portfolioName} onChange={(e) => setPortfolioName(e.target.value)}/>
        </div>

        <div className='details-row'>
          <div>Account Size:</div>
          <input type="text" className="indicator-input" placeholder="Account Size" value={accountSize} onChange={(e) => setAccountSize(e.target.value)}/>
        </div>


        </div>
        )}

        <div className="strategyDetailsHeader">
            <button onClick={toggleEntryDetails} className={`toggle-button ${showEntryDetails ? 'expanded' : 'collapsed'}`}>
              <span className="arrow-icon">{showEntryDetails ? '▼' : '▶'}</span>
            </button>
            <p className="strategy-details-title">Portfolio Strategies:</p>
          </div>

        {showEntryDetails && (      
        <div className="chatbar-conditions">
          {conditions.map((condition, index) => (
            <ConditionRow 
              key={condition.id}
              conditionn={condition} 
              onConditionChange={(updatedCondition) => updateCondition(index, updatedCondition)}
              onDelete={() => deleteCondition(index)}
            />
          ))}
          <div className="add-condition">
            <button onClick={addStrategy} type="button">Add Strategy</button>
          </div>
        </div>
        )}    

      </div>
          
    </div>
  );
}

export default ChatBar;
