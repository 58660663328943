import React, { useState, useEffect } from 'react';
import ValueRow from '../valueRow/valueRow';
import { v4 as uuidv4 } from 'uuid';
import '../../utils/rightBar/Rightbar.css';
import useAccessToken from '../../utils/helpers/getAccessToken';

function ChatBar( {selectedIndicator, handleInsert, showToast} ) {
  
  const getAccessToken = useAccessToken();
  const [inidcatorName, setIndicatorName] = useState('');
  const [type, setType] = useState('');
  const [display, setDisplay] = useState('');
  const [operations, setOperations] = useState([]);
  const [inidcatorExpression, setIndicatorExpression] = useState('');



  useEffect(() => {
    if (selectedIndicator != null) {
      const fetchDetailsForIndicator = async () => {
        try {  
          const accessToken = await getAccessToken();
          const response = await fetch(`${process.env.REACT_APP_GET_CUSTOM_INDICATOR_DETAILS}?indicatorId=${selectedIndicator.id}`
            , {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          const data = await response.json();
          
          setIndicatorName(data.name);
          setType(data.type);
          setDisplay(data.display);
        
          const loadedOperations = data.values.map(value => ({
            id: uuidv4(),
            ...value
          }));
        
          setOperations(loadedOperations);

        } catch (error) {
          console.error('Failed to fetch indicator details:', error);
        }
      };

      fetchDetailsForIndicator();
    } else {
      setIndicatorName('');
      setType('');
      setDisplay('');
      setOperations([]);
    }
  }, [selectedIndicator]);


  const addOperation = () => {
    const newOperation = {
      id: uuidv4(),
      operations: [{comparison: 'null', value: ''}], 
    };
    const newOperations = [...operations, newOperation];
    setOperations(newOperations);
  };


  const deleteOperation = (operationIndex) => {
    const newOperations = operations.filter((_, index) => index !== operationIndex);    
    setOperations(newOperations);
  };

  const updateOperation = (operationIndex, updatedOperation) => {
    const newOperations = operations.map((operation, index) => 
      index === operationIndex ? { ...operation, ...updatedOperation } : operation
    );
    setOperations(newOperations);
    console.log(operations);
  };

  
  const [showStrategyDetails, setStrategyShowDetails] = useState(true);
  const [showEntryDetails, setEntryShowDetails] = useState(true);
  const [showExpressionDetails, setExpressionDetails] = useState(true);

  const toggleIndicatorExpression = () => {
    setExpressionDetails(!showExpressionDetails);
  };

  const toggleStrategyDetails = () => {
    setStrategyShowDetails(!showStrategyDetails);
  };

  const toggleEntryDetails = () => {
    setEntryShowDetails(!showEntryDetails);
  };


  const clearIndicator = () => {
    setIndicatorName('');
    setType('');
    setDisplay('');
    setOperations([]);
  };




  const handleRunClick = async () => {

    console.log(operations);

    const payload = {
      name: inidcatorName,
      type: type,
      display: display,
      indicatorExpression: inidcatorExpression,

      operations: operations

    };

    
    try {
      const accessToken = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_RUN_INDICATOR_API}`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.message && data.indicatorId && data.indicatorName) {
        handleInsert(data.indicatorId, data.indicatorName);
      }      
    } catch (error) {
      console.error('There was an error running the indicator', error);
    }


  };

  return (
    <div className="chatbar-container">


      <div className="chatbar-header">
        <div className="titletext">
        Indicator Creator
        </div>
        <div className="chatbar-run">
        <button type="button" onClick={clearIndicator}>Create New Indicator</button>
        </div>
        <div className="chatbar-run">
        <button type="button" onClick={handleRunClick}>Save & Run</button>
        </div>

      </div>


      <div className="scrollable-content">
        
        <div className="strategyDetailsHeader">
            <button onClick={toggleStrategyDetails} className={`toggle-button ${showStrategyDetails ? 'expanded' : 'collapsed'}`}>
              <span className="arrow-icon">{showStrategyDetails ? '▼' : '▶'}</span>
            </button>
            <p className="strategy-details-title">Indicator Details:</p>
        </div>

        {showStrategyDetails && (
        <div className = "strategyDetails">
        
        <div className='details-row'>
          <div>Indicator Name:</div>
          <input type="text" className="indicator-input" placeholder="Indicator Name" value={inidcatorName} onChange={(e) => setIndicatorName(e.target.value)}/>
        </div>

        <div className="details-row">
          <div>Indicator Type:</div>
          <select className="market-dropdown" value={type} onChange={(e) => setType(e.target.value)}>
            <option value="" disabled hidden>Select Type:</option>
            <option value="line">Line</option>
            <option value="signal">Signal</option>
          </select>
        </div>


        <div className="details-row">
          <div>Display On:</div>
        <select className="market-dropdown" value={display} onChange={(e) => setDisplay(e.target.value)}>
          <option value="" disabled hidden>Display On:</option>
          <option value="price">Price</option>
          <option value="lower">Lower</option>
        </select>
        </div>
        
        </div>
        )}

        <div className="strategyDetailsHeader">
            <button onClick={toggleEntryDetails} className={`toggle-button ${showEntryDetails ? 'expanded' : 'collapsed'}`}>
              <span className="arrow-icon">{showEntryDetails ? '▼' : '▶'}</span>
            </button>
            <p className="strategy-details-title">Indicator Operations:</p>
          </div>

        {showEntryDetails && (      
        <div className="chatbar-conditions">
          {operations.map((operation, index) => (
            <>
            <p className="condition-title">Variable {String.fromCharCode(65 + index)}</p>
            <ValueRow 
              key={operation.id}
              conditionn={operation} 
              onConditionChange={(updatedOperation) => updateOperation(index, updatedOperation)}
              onDelete={() => deleteOperation(index)}
            />
            </>
          ))}
          <div className="add-condition">
            <button onClick={addOperation} type="button">Add Value</button>
          </div>
        </div>
        )}    

        <div className="strategyDetailsHeader">
            <button onClick={toggleIndicatorExpression} className={`toggle-button ${showExpressionDetails ? 'expanded' : 'collapsed'}`}>
              <span className="arrow-icon">{showExpressionDetails ? '▼' : '▶'}</span>
            </button>
            <p className="strategy-details-title">Indicator Expression:</p>
        </div>

        {showExpressionDetails && (
        
          <input type="text" className="indicator-input" placeholder="Indicator Expression" value={inidcatorExpression} onChange={(e) => setIndicatorExpression(e.target.value)}/>

        )}


      </div>
          
    </div>
  );
}

export default ChatBar;
